const IMAGES = {
  // LOGO
  //HOME
  banner1: require("../img/home/banner1.webp"),
  banner2: require("../img/home/banner2.webp"),
  banner3: require("../img/home/banner3.webp"),
  banner4: require("../img/home/banner4.webp"),
  banner5: require("../img/home/banner5.webp"),
  phonepe: require("../img/products/phonepe.png"),
  // home slider
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
  // contact
  support: require("../img/support.png"),
  support2: require("../img/support2.png"),
};

export default IMAGES;
